// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery")
require("moment/locale/ru")
require("slick-carousel")
require("slick-carousel/slick/slick.css")
require("slick-carousel/slick/slick-theme.css")

import moment from 'moment'
import salert from 'sweetalert2'

import $ from 'jquery';

moment.locale('ru')

const formatTime = (element) => {
  return element.textContent = moment(element.dataset['date'], "YYYY-MM-DD").fromNow()
}
const formatFullTime = (element) => {
  return element.textContent = moment(element.dataset['date'], "YYYY-MM-DD").format('DD.MM.YYYY')
}

$(document).on('turbolinks:load', function () {

  let searchIn = document.querySelectorAll('.header-search__mobile'),
    searchOverlay = document.querySelector('.search'),
    closeBtn = document.querySelector('.close');


  searchIn.forEach(el => {
    el.addEventListener('click', function () {
      searchOverlay.classList.add('open');
    })
  });

  closeBtn.addEventListener('click', function () {
    searchOverlay.classList.remove('open');
  });


  let mainSlider = document.querySelector('.slider__wrapper')

  if (mainSlider) {
    let items = document.querySelectorAll('.slider-item');
    let current = 0;

    function slider() {
      for (let i = 0; i < items.length; i++) {
        items[i].classList.add('opacity0');
      }
      items[current].classList.remove('opacity0');
      if (current + 1 == items.length) {
        current = 0;
      } else {
        current++
      }
    }

    let slideIndex = 1,
      slides = document.querySelectorAll('.slider-item'),
      prev = document.querySelector('.slider-prev'),
      next = document.querySelector('.slider-next'),
      dotsWrap = document.querySelector('.slider-dots'),
      dots = document.querySelectorAll('.dot');

    showSlides(slideIndex);

    function showSlides(n) {
      if (n > slides.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = slides.length;
      }

      slides.forEach((item) => item.style.display = 'none');
      dots.forEach((item) => item.classList.remove('dot-active'));

      if (!!slides[slideIndex - 1]) {
        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].classList.add('dot-active');
      }
    }

    function plusSlides(n) {
      showSlides(slideIndex += n);
    }

    function currentSlide(n) {
      showSlides(slideIndex = n);
    }

    prev.addEventListener('click', function () {
      plusSlides(-1);
    });
    next.addEventListener('click', function () {
      plusSlides(1);
    });

    dotsWrap.addEventListener('click', function (event) {
      for (let i = 0; i < dots.length + 1; i++) {
        if (event.target.classList.contains('dot') && event.target == dots[i - 1]) {
          currentSlide(i);
        }
      }
    });
  }





  const $timeElems = Array.from(document.getElementsByClassName('jsDate'))
  $timeElems.map(e => formatTime(e))

  const $fullTimeElems = Array.from(document.getElementsByClassName('jsFullDate'))
  $fullTimeElems.map(e => formatFullTime(e))

  $('.jsClosePopup').click(e => {
    $(`.${e.target.dataset.class}`).removeClass('visible')
  })

  $('.jsOpenPopup').click(e => {
    $(`.${e.target.dataset.class}`).addClass('visible')
  })

  $('.modal').click(function (e) {
    if ($(e.target).hasClass('modal')) {
      $(this).removeClass('visible')
    }
  })

  $("#feedbackForm").on('submit', function (e) {
    e.preventDefault()
    $.ajax({
      method: 'POST',
      url: '/forms/feedback',
      data: $(this).serialize(),
      success: () => {
        new salert({
          type: 'success',
          title: 'Спасибо за заявку!'
        })
      },
      error: (e) => {
        new salert({
          type: 'error',
          title: 'Ошибка',
          text: "Произошла ошибка при отправке формы",
        })
      }
    })
  })

  $("#newsForm").on('submit', function (e) {
    e.preventDefault()
    $.ajax({
      method: 'POST',
      url: '/forms/suggest_news',
      data: $(this).serialize(),
      success: () => {
        new salert({
          type: 'success',
          title: 'Новость отправлена!'
        })
      },
      error: (e) => {
        new salert({
          type: 'error',
          title: 'Ошибка',
          text: "Произошла ошибка при отправке формы",
        })
      }
    })
  })

  $("#subscribeForm").on('submit', function (e) {
    e.preventDefault()
    const formattedData = $(this).serializeArray().reduce((store, item) => {
      if (!store[item.name]) {
        store[item.name] = item.name === 'subscribe[themes]' ? JSON.parse(item.value) : item.value
      }

      return store
    }, {})

    $.ajax({
      method: 'POST',
      url: '/forms/subscribe',
      data: formattedData,
      success: () => {
        new salert({
          type: 'success',
          title: 'Спасибо за подписку!'
        })
      },
      error: (e) => {
        new salert({
          type: 'error',
          title: 'Ошибка',
          text: "Произошла ошибка при отправке формы",
        })
      }
    })
  })

  // "catalog?category=1&brands[]=1&brands[]=2"
  $("#filters-form").on('submit', function (e) {
    e.preventDefault()
    const formData = $(this).serializeArray()

    let urlParams = new URLSearchParams(window.location.search);
    const categoryParam = urlParams.get('category')

    if (categoryParam) {
      urlParams.set('category', categoryParam)
    }

    urlParams.delete("brands[]");

    formData.forEach(data => {
      urlParams.append('brands[]', data.name)
    })

    window.location.search = urlParams.toString()
  })

  const marketingSlider = $('.marketing-banner-slider');
  if (marketingSlider.length) {
    marketingSlider.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnFocus: false
    });
  }

  $('.marketing-banner__menu-top').click(function() {
    $('.marketing-banner__menu-top').removeClass('marketing-banner__menu-bottom--active');

    $(this).next('.marketing-banner__menu-bottom').addClass('marketing-banner__menu-bottom--active');
  });

  $(document).click(function(event) {
    if (!$(event.target).closest('.marketing-banner__menu').length) {
      $('.marketing-banner__menu-bottom').removeClass('marketing-banner__menu-bottom--active');
    }
  });
});

require("channels");

console.log('load packs')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function() {
  const $dropdown = $('.jsDropdown');
  $dropdown.on('click', function() {
    $(this).toggleClass('show');
    $(this).next().slideToggle();
  });
  $(document).click(function (e) {
    if (!$(e.target).closest('.footer-nav__list').length) {
        $dropdown.removeClass('show');
        $dropdown.next().slideUp();
    }
});

})